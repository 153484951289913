.WebPlayBackPage{
    width: 100%;
    height: 100%;
}

.WebPlayBackPage-currentlyPlaying{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.WebPlayBackPage-currentlyPlaying-backgroundImage{
    height: 100%;
    width: 100%;
    filter: blur(10px);
    position: absolute;
    background-position: center;
    background-size: cover;
    z-index: -1;
}

.WebPlayBackPage-currentlyPlaying-albumCover{
    width: 100%;
    border-radius: 10px;
}

.WebPlayBackPage-currentlyPlaying-info{
    color: black;
    text-align: center;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    margin: 10px 0 0 0 ;
}

.WebPlayBackPage-currentlyPlaying-info-name{

}

.WebPlayBackPage-currentlyPlaying-info-artist{

}

.WebPlayBackPage-voteMusicCards{
    width: calc(100% - 30px);
    padding: 0 10px 10px 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
}
