.notPlayingPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #1b3a58;
}

.notPlayingPage-text{
    color: white;
}
