.LoginPage{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginButtonText{
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    padding: 10px;
    background-color: #1f2658;
    border-radius: 10px;
    transition: transform 0.1s ease-in-out;
}

.loginButtonText:active{
    transform: scale(1.1);
}
