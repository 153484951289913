.musicCard{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 5px 5px 15px -2px rgba(0,0,0,0.52);
    transition: transform 0.05s ease-in-out;
}

.musicCard-albumArt{
    width: 100%;
    border-radius: 10px;
    margin: 0 10px 0 0 ;
}

.musicCard-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
}

.musicCard-text-name{
    margin: 0;
    flex: 0 0 auto;
    text-align: center;
}

.musicCard-text-artistName{
    margin: 0;
    flex: 0 0 auto;
    color: grey;
    text-align: center;
}

.musicCard:active{
    transform: scale(1.02);
}
