html, body, #app, #app>div {
  height: 100%
}

body{
  margin: 0;
}

body > #root > div {
  height: 100vh;
}

* {
  touch-action: manipulation;
}
